<template>
    <div class="productContainer" v-loading="loading">
        <!-- 面包屑 -->
        <div class="bread">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item class="p_bread" :to="{ path: '/products/productsCenter', query:{categoryId:'68'} }">产品中心</el-breadcrumb-item>
                <el-breadcrumb-item class="p_bread" :to="{ path: '/products/productsCenter', query:{categoryId:p_categories.id} }">{{p_categories.name}}</el-breadcrumb-item>
                <el-breadcrumb-item >{{content.name}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main" >
            <!-- 标题 -->
            <!-- <div class="title">{{content.name}}</div> -->
            <!-- 产品概述 -->
            <div class="overview module">
                <div class="overviewTitle moduleTitle">产品概述</div>
                <div class="overviewContent moduleContent" v-html="content.productOverview"></div>
            </div>
            <div id="maodianDiv">
            <!-- 产品阵容 -->
            <div  class="lineup module" v-if="content.img||categoryId==1">
                <div id="lineupTitle" class="lineupTitle moduleTitle">产品阵容</div>
                <div class="lineupContent moduleContent">
                    <!-- content.name=='USB芯片' -->
                    <div v-if="categoryId==1" :style="{width:isMoPhone?'100%':'92%'}"  class="usbImgPart">
                        <div @click="clickUsbImgItem(item)" class="usbImgItem" :style="{width:isMoPhone?'19%':'18%', margin:isMoPhone?'0px 0.5%':'0px 1%', marginBottom:isMoPhone?'2px':'20px'}"  v-for="(item,index) in content.productTables" :key="index">
                            <img  :src="item.img" alt="">
                        </div>
                    </div>
                    <div v-else  :style="{width:isMoPhone?'100%':(isOver2k?'65%':'80%'), height:isMoPhone?'100%':(isOver2k?'65%':'80%'),}">
                        <div v-if="categoryId==4||categoryId==63||categoryId==71||categoryId==70||categoryId==75" >
                           <line-up ref="lineUp" :categoryId="categoryId" ></line-up>
                        </div>
                        <img v-else style="width:100%; heght:100%;" :src="content.img" alt="">
                    </div>
                    
                    <!-- 文字链接 -->
                    <el-row :gutter="0" :style="textLinkStyle" >
                        <el-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24">
                            <div  v-if="content.moreProductTitle" :style="{marginTop:categoryId==1?'-15px':'5px'}"  class="moreText" >
                                <span style="cursor: pointer;" @click="clickMoreProduct">{{content.moreProductTitle}} -></span> 
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <!-- 产品选型 -->
            <div id="lectotype" class="lectotype module" v-if="content.productTables&&content.productTables.length" >
                <div class="lectotypeTitle moduleTitle">产品选型</div>
                <div class="lectotypeContent moduleContent">
                    <!-- 单表格 -->
                    <div v-if="content.productTables&&content.productTables.length==1" >
                        <el-table  :data="content.productTables[0].value" style="min-width:150px;"  border :header-cell-style='getHeadClass' :max-height="isMoPhone?500:pcTableMaxHeight">
                            <el-table-column :fixed='index==0 && !isMoPhone ? true: null'  v-for="(item,index) in content.productTables[0].meta" :key="index" 
                            :label="item.title" :property="item.field" :align="index==0?'center':item.align" :width="index==0?(item.tableId==93||item.tableId==94||item.tableId==38||item.tableId==98||item.tableId==53||item.tableId==54||item.tableId==24||item.tableId==28||item.tableId==31?140:100):(isMoPhone&&(item.field=='td'||item.field=='gn')?280:item.width)">
                                <template slot-scope="scope">
                                    <span v-if="item.head==1">
                                        <a v-if="scope.row.url" style="color:#337ab7; font-size:14px; cursor:pointer; text-align:center;" :href="scope.row.url" target="_blank" >{{scope.row[scope.column.property]}}</a>
                                        <span v-else style="font-size:14px;">
                                            {{scope.row[scope.column.property]}}
                                        </span>  
                                    </span>
                                    <span v-else-if="scope.column.property=='td' && scope.row.td">
                                        <div  v-html="scope.row.td"></div>
                                    </span>
                                    <span v-else >
                                        <span v-if="scope.row[scope.column.property]" v-html="scope.row[scope.column.property]"></span>
                                        <span v-else>-</span>
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="display:flex; justify-content:end;">
                            <div v-if="content.moreProductTableTitle" class="tableMoreText" @click="clickMoreProductTable">{{content.moreProductTableTitle}} -></div>
                        </div>
                        <div class="memo" v-html="content.productTables[0].memo"></div>
                    </div>
                    
                    <!-- 多表格 -->
                    <el-tabs type="border-card" v-else v-model="activeTab" @tab-click="handleTabClick">
                        <el-tab-pane  v-for="(item,index) in content.productTables" :key="index" :name="String(item.title).replace(/\&/g,'_')">
                            <template slot="label">
                                <span class="tabLabelClass"></span>{{item.title}}
                            </template>
                            <el-table  :data="item.value" style="min-width:150px;"  border :header-cell-style='getHeadClass' :max-height="isMoPhone?500:pcTableMaxHeight">
                                <el-table-column :fixed='i==0 && !isMoPhone ? true: null'  v-for="(t,i) in item.meta" :key="i" 
                                :label="t.title" :property="t.field" :align="i==0?'center':t.align" :width="i==0?(t.tableId==37||t.tableId==20||t.tableId==75||t.tableId==78||t.tableId==77?140:100):(isMoPhone&&(t.field=='td'||t.field=='gn')?280:t.width)">
                                    <template slot-scope="scope">
                                        <span v-if="t.head==1">
                                            <a v-if="scope.row.url" style="color:#337ab7; font-size:14px; cursor:pointer; text-align:center;" :href="scope.row.url" target="_blank" >{{scope.row[scope.column.property]}}</a>
                                            <span v-else style="font-size:14px;">
                                                {{scope.row[scope.column.property]}}
                                            </span>
                                        </span>
                                        <span v-else-if="scope.column.property=='td' && scope.row.td">
                                            <div  v-html="scope.row.td"></div>
                                        </span>
                                        <span v-else >
                                            <span v-if="scope.row[scope.column.property]" v-html="scope.row[scope.column.property]"></span>
                                            <span v-else>-</span>
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="memo" v-html="item.memo"></div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
          </div>
        </div>
       
    </div>
</template>
<script>
import lineUp from '../lineUp/index.vue'
import { productTable } from '@/services/product.js'
export default {
  name: "mcuInterface",
  components:{
    lineUp,
  },
  data() {
    return {
        loading:false,
        categoryId:'',
        content:{},
        p_categories:{},
        activeTab:'',
        url:{
          contentUrl:'/api/official/website/productTables/product'
        },
        windowWidth:window.innerWidth,
        isMoPhone:false,
        pcTableMaxHeight:600,
        isOver2k:false,
    };
  },
  computed: {
    //设置阵容图底部文字链接宽度
    textLinkStyle(){
        let widthValue =''
        if(this.categoryId==1){
            widthValue = '91%'
        }else{
            widthValue = '85%'
        }
        return {
            width:widthValue
        }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth <= 768) {
        this.isMoPhone = true
        this.isOver2k = false
      }else{
        this.isMoPhone = false
        if(newWidth>=2500){
            this.isOver2k = true
        }else{
            this.isOver2k = false
        }
      }
    },
     $route(to, from) {// 对路由参数变化作出响应，加载不同模块数据
        console.log('监听路由触发')
        if(to.name!='Products' && to.name!='OtherChip'){//排除产品中心页面和其他芯片页面
            console.log('监听路由to',to)
            this.categoryId = to.query.categoryId
            if(to.query.categoryId){
              this.getContent()
            }
        }
    }
  },
  mounted() {
    if (this.windowWidth <= 640) {
        this.isMoPhone = true
        this.isOver2k = false
    }else{
        this.isMoPhone = false
        if(this.windowWidth>=2500){
            this.isOver2k = true
        }else{
            this.isOver2k = false
        }
    }
  },
  activated(){
    console.log('mcuInterface的路由参数---',this.$route.query)
    if(this.$route.query.categoryId){//页面参数
        this.categoryId = this.$route.query.categoryId
        this.$emit('giveCategoryId',this.categoryId)//将当前激活侧边菜单传递给父组件
        
        this.getContent()
    }
    console.log('可视高度----',window.innerHeight)
    if(window.innerHeight<=700 && window.innerHeight>600){
        this.pcTableMaxHeight = 650
    }else if(window.innerHeight<=600){
        this.pcTableMaxHeight = 600
    }else{
        this.pcTableMaxHeight = 800
    }
  },
  methods:{
    //获取页面内容
    getContent(){
      productTable({categoryId:this.categoryId}).then(res => {
          this.loading = true;
          console.log('res',res)
          if(res.data.code===0 && res.data.data){
            this.content = res.data.data
            document.title = this.content.name+' - 南京沁恒微电子股份有限公司'
            this.content.productTables.forEach(item=>{
                item.meta = JSON.parse(item.meta)
                item.value = JSON.parse(item.value)
            })
            this.p_categories = this.content.p_categories?this.content.p_categories:{}
            
            if(this.$route.query.tName){//存在表格参数（多表格的情况下）
                this.activeTab = this.$route.query.tName.replace(/\&/g,'_')
                 
                setTimeout(()=>{
                    const maodianDiv = document.getElementById('maodianDiv');
                    const offsetTop = maodianDiv.offsetTop+20;
                    window.scrollTo({
                        top: offsetTop,
                        behavior: "smooth",
                    });
                },10)
            }else{// 没有表格参数，默认选中第一个tab,同时滚动条置于顶部
                this.activeTab = this.content.productTables[0]?this.content.productTables[0].title.replace(/\&/g,'_'):''
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
            console.log('表格数据--',this.content.productTables)
          }else{
            this.$message.error(res.data.message);
          }
          setTimeout(()=>{
            this.loading = false;
          }, 100)
      });
    },
    
    //自定义表头样式
    getHeadClass({rowIndex}){
        if(rowIndex==0){
          return 'background-color:#f2f2f2;color:#666;font-size:16px;text-algin:center; '
        }
        else{
          return "color:#666; font-size:14px;text-algin:center; background-color:#fff; "
        }
    },

    //切换tab
    handleTabClick(e){
        this.activeTab = e.name.replace(/\&/g,'_')//.replace(/\&/g,'_')
        let newUrl = this.changeURLParam(window.location.href, 'tName', this.activeTab)//地址栏参数也要变化
        history.replaceState(null, null, newUrl);  // 替换地址栏

        this.$route.query.tName = this.activeTab
    },
    //点击USB页面的产品阵容图（多图）
    clickUsbImgItem(item){
        this.handleTabClick({name:item.title})
        setTimeout(()=>{
            const lectotype = document.getElementsByClassName('lectotype')[0];
            const offsetTop = lectotype.offsetTop
            window.scrollTo({
                top: offsetTop,
                behavior: "smooth",
            });
        },10)
    },

    //修改地址栏参数
    changeURLParam(url, type, value) {
        let reg = eval('/([\?|&]' + type + '=)[^&]*/gi');
        value = value.toString().replace(/(^\s*)|(\s*$)/g, ""); // 移除首尾空格
        let url2 = '';
        if (!value) { // remove
            url2 = url.replace(reg, '');
        } else {
            if (url.match(reg)) { // edit
                url2 = url.replace(reg, '$1' + value);
            } else { // add
                url2 = url + (url.indexOf('?') > -1 ? '&' : '?') + type + '=' + value;
            }
        }
        // console.log('url2--',url2)
        return url2;
    },
    //点击阵容图更多产品
    clickMoreProduct(){
       window.open(this.content.moreProductUrl)
    },
    //点击选型表更多产品
    clickMoreProductTable(){
       window.open(this.content.moreProductTableUrl)
    },

    //点击型号跳转详情页
    gotoChipDetail(model){
        window.open(this.content.moreProductTableUrl)
        this.$router.push({ path: 'products/'+model, query: {categoryId:this.categoryId}})

    },

   
  }
};
</script>
<style scoped>
 .productContainer{
    width: 100%;
    text-align: left;
 }
 .overview{
    margin-top: 20px;
 }
 .main .title{
    font-size: 28px;
    color: #002c85;
    font-weight: 600;
    margin: 50px 0px;
    letter-spacing: 0px;
 }
 .main .moduleTitle{
    font-size: 22px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 20px;
 }
 .main .moduleContent{
    color:#000;
    letter-spacing: 0px;
    margin-bottom:20px;
 }
 .main .lineupContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
 }
 .main .lineupContent .usbImgPart{
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
 }
 .usbImgItem{
    width: 18%;
    margin: 0px 1%;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.2s;
 }
 .usbImgItem:hover{
   transform: scale(1.05);
 }
 .usbImgItem img{
    width: 100%;
 }
 .moreText{
    /* margin-top: 10px; */
    color: #337ab7;
    font-size: 14px;
    /* width: 65%; */
    text-align: right;
 }
 .tableMoreText{
    margin-top: 10px;
    color: #337ab7;
    font-size: 14px;
    cursor: pointer;
    text-align: right;
 }
 
 .main .lectotypeContent {
    width: 100%;
 }
 .main .lectotypeContent .el-table{
    font-size: 14px !important;
 }
 .lectotypeContent .el-table td.el-table__cell, /deep/.el-table th.el-table__cell.is-leaf{
    border-bottom: 1px solid #dfdfdf;
 }
 .lectotypeContent /deep/.el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
    border-right: 1px solid #dfdfdf;
    padding: 4px 0px;
 }
 .lectotypeContent /deep/.el-table--border, .el-table--group{
    border: 1px solid #e1e1e1;
 }
 .lectotypeContent /deep/.el-tabs--border-card>.el-tabs__content{ 
    padding: 15px 10px;
 }
 .lectotypeContent /deep/.el-tabs__item{
    font-size: 16px;
    font-weight: 700;
 }
 .lectotypeContent /deep/.el-tabs--border-card>.el-tabs__header .el-tabs__item{
    color: #333;
 }
 .lectotypeContent /deep/.el-tabs--border-card>.el-tabs__header{
    background-color: #f2f2f2;
 }
 .lectotypeContent /deep/.el-table th.el-table__cell>.cell{
    letter-spacing: 0px;
    /* font-weight: 500; */
    font-size: 14px;
 }
 .lectotypeContent /deep/.el-table--border th.el-table__cell.gutter:last-of-type {
    border-right: none;
    border-bottom: none;
 }
  
 .lectotypeContent .tabLabelClass{
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-bottom: 3px;
    background: #000;
    border-radius: 50%;
    margin-right: 10px;
 }
 .lectotypeContent .memo{
    margin-top: 5px;
    white-space: pre-wrap;
    font-size: 14px;
    color: #333;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }
 .el-breadcrumb__item{
    margin-bottom: 5px;
}

@media screen and (max-width: 688px) {
    .main .lectotypeContent {
        width: 110%;
        margin-left: -5%;
    }
    .lectotypeContent /deep/.el-tabs--border-card>.el-tabs__content{ 
        padding: 10px 4px;
    }
}
</style>